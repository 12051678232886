"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
 import { API } from "./API";

 const API_CONFIG_APP = process.env.APP_CONFIG.api.strapi;

/**
 *	----------------------------------------------------------------------------------------------
 *	Class StrapiAPI 
 * 	----------------------------------------------------------------------------------------------
 */
 class StrapiAPI extends API {

	constructor(){ 
		super(API_CONFIG_APP);

		this.headers = new Headers();
	}
	
	async loadGlobalData() {
		const result = await this.get(`/global?locale=${app.i18n.getLanguage()}&populate=deep,4`);
		return this.refactorResult(result, "global");
  	}

	async loadPageData(page_name) {
		const result = await this.get(`/${page_name}?locale=${app.i18n.getLanguage()}&populate=deep,7`);
		return this.refactorResult(result, page_name);
  	}

	refactorResult(result, mockup = ""){
		const values = result?.data || this.getMockups(mockup);
		if(values.localizations){
			delete values.localizations;
		}
		return values;
	}

	async getMockups(name){
		const Module = await import(/* webpackChunkName: "mockup" */ `./mockups/${name}.json`).catch((err) => { console.error(err); });
		const data = {};
		for(let key in Module){
			data[key] = Module[key];
		}
		console.log("mockup data: " + name, data);
		return data;
	}
}

const strapiAPI = new StrapiAPI();

export {strapiAPI, StrapiAPI};